import React from 'react';
import './ImpressumView.css';
import {Helmet} from "react-helmet";

function ImpressumView() {
    return (
        <>
            <Helmet>
                <title>Vaynie - Impressum</title>
                <meta name="robots" content="noindex" />
            </Helmet>
            <section className="container" id='impressum'>
                <h2>Impressum</h2>
                <p><strong>Kontakt-Adresse</strong></p>
                <p>Vaynie KLG<br/>Im Kunzental 8<br/>4310 Rheinfelden<br/>Schweiz</p>
                <p>E-Mail: info@vaynie.com</p>
                <p><strong>Vertretungsberechtigte Person(en)</strong>
                </p>Vay Lien Chang, Co-Founder<br/>Leonie Jacobs, Co-Founder<p>&nbsp;</p>
                <p>
                    <strong>Handelsregister-Eintrag</strong></p>
                <p>Eingetragener Firmenname: Vaynie KLG<br/>Handelsregister Nr:
                    CHE-319.593.696</p>
                <p><strong>Haftungsausschluss f&uuml;r Links</strong></p>
                <p>Verweise und
                    Links auf Webseiten Dritter liegen ausserhalb unseres Verantwortungsbereichs. Es wird jegliche
                    Verantwortung
                    f&uuml;r solche Webseiten abgelehnt. Der Zugriff und die Nutzung solcher Webseiten erfolgen auf
                    eigene
                    Gefahr des jeweiligen Nutzers.</p>

                <br/>Quelle: <a href="https://www.swissanwalt.ch" rel="noreferrer noopener"  target="_blank"
                                >SwissAnwalt</a>
            </section>
        </>
    )
}

export default ImpressumView

import React from 'react';

function AffiliateView() {
    return (
        <>
            <section id="wealthStats">
                <div className='container vh-100'>
                    <h4>Affiliates</h4>

                    <h2>Adobe</h2>
                    <p>Mit der Adobe Cloude hast du Zufriff auf über 20 Applikationen, die im Bereich Fotographie, Video, Desin, Web, UX und Social Media eingesetzt werden können.</p>
                    <a href="https://clk.tradedoubler.com/click?p=264304&a=3255010"
                       target="_new"><button className='btn btn-primary'>Adobe Creative Cloud</button></a>

                </div>
            </section>
        </>
    )
}

export default AffiliateView
import React from 'react';
import {FaLinkedin} from "react-icons/fa";

function TeamMember({img, name, position, strengths, studies, linkedIn}){
    return(
        <>
            <div className="card">
                <img className="card-img-top" src={img} alt={name}/>
                <div className="card-body">
                    <h5 className="card-title">{name}</h5>
                    <p className="card-text">{position}</p>
                    <p><strong>Stärken</strong>: <br/>{strengths}</p>
                    <p><strong>Studium</strong>:<br/> {studies[0]} <br/>{studies[1]}</p>
                    <a target="_blank" rel="noreferrer noopener"  href={linkedIn}>
                        <FaLinkedin className="fs-4 btn-linkedin"/></a>
                </div>
            </div>
        </>
    )
}

export default TeamMember
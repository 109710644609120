import React, {useEffect, useState} from 'react';
import {FaBars, FaTimes} from 'react-icons/fa'
import './NavBar.css'
import logo from '../assets/img/logo-primary-png.png';


function NavBar() {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);
    const showButton = () => {
        if (window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true)
        }
    }

    useEffect(() => {
        showButton();

    }, []);

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    }

    window.addEventListener('resize', showButton)

    return (<>
        <div className='navbar' id='navbar'>
            <div className="navbar-container container">
                <a href='/' className="navbar-logo js-scroll-trigger " onClick={scrollToTop}>
                    <img src={logo} alt='Vaynie'/>
                </a>
                <div className="menu-icon" onClick={handleClick}>
                    {click ? <FaTimes/> : <FaBars/>}
                </div>
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                    <li className='nav-item'>
                        <a href='/#services' className="nav-links js-scroll-trigger" onClick={closeMobileMenu}>
                            Leistungen
                        </a>
                    </li>
                    <li className='nav-item'>
                        <a href='/#skills' className="nav-links js-scroll-trigger" onClick={closeMobileMenu}
                        >
                            Skills
                        </a>
                    </li>
                    <li className='nav-item'>
                        <a href='/#about' className="nav-links js-scroll-trigger" onClick={closeMobileMenu}
                        >
                            Über uns
                        </a>
                    </li>
                    <li className='nav-item'>
                        <a href='/#contact' className="nav-links js-scroll-trigger"
                           onClick={closeMobileMenu}>Kontakt</a>
                    </li>
                </ul>
            </div>
        </div>

    </>)
}

export default NavBar;
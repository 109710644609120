import React from 'react';
import './TeamView.css'
import {teamMemberVay, teamMemberLeo} from "../Data";
import TeamMember from "../../../TeamMember";

function TeamView(){
    return(
        <>
            <section id="team">
                <div className="container ">
                    <h2 className="text-center ">Team</h2>
                    <p className="text-center">Unser fantastisches Team besteht bis jetzt aus zwei motivierten hellen
                        Köpfchen.
                        Wir hoffen natürlich, bald Zuwachs zu bekommen.</p>
                    <div className="d-flex flex-wrap justify-content-around">
                        <TeamMember {...teamMemberVay}/>
                       <TeamMember {...teamMemberLeo}/>
                    </div>
                </div>
            </section>
        </>
    )
}

export default TeamView;
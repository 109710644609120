import kita from '../../../assets/img/portfolio/thumbnails/kita_logo.png'
import klimaball from '../../../assets/img/portfolio/thumbnails/klimaball.png'
import chiro from '../../../assets/img/portfolio/thumbnails/chiro.png'
import nicer from '../../../assets/img/portfolio/thumbnails/nicer_logo.png'
import imgVay from "../../../assets/img/VayLienChang.jpg";
import imgLeo from '../../../assets/img/Leonie.jpg';
import sic2021 from '../../../assets/img/banner/AuszeichnungTeilnehmendeSIC2021.png'
import sic2022 from '../../../assets/img/banner/Teilnehmende 2022.png'
import stc2022 from '../../../assets/img/banner/swiss-startup-challenge.png'
/*banner*/
export const sic21 = {
    img: sic2021
}

export const sic22 = {
    img: sic2022
}

export const stc22 ={
    img: stc2022
}

/*Team*/
export const teamMemberVay = {
    img: imgVay,
    name: 'Vay Lien',
    position: 'Co-Founder',
    strengths: 'Projektmanagement, Backend Programmierung',
    studies: ['MSc Business Information Systems\n', 'BSc Business Information Technology\n' +
    '\n'],
    linkedIn: 'https://www.linkedin.com/in/vay-lien-chang-223ab8186/'

}
export const teamMemberLeo = {
    img: imgLeo,
    name: 'Leonie',
    position: 'Co-Founder',
    strengths: 'UI/UX Design, Frontend Programmierung, SEO & Marketing',
    studies: ['BSc Informatik - Profilierung iCompetence\n'],
    linkedIn: 'https://www.linkedin.com/in/leonie-jacobs-vaynie'

}
/*Portfolio*/
export const dataNicer =
    {
        src: nicer,
        alt: 'Nicer Logo',
        projectCategory: 'Logo',
        projectName: 'Nicer'
    }

export const dataChiro =
    {
        src: chiro,
        alt: 'Videoplattform Chiropraktische Medizin',
        projectCategory: 'Videoplattform inkl. CMS-System',
        projectName: 'Balgrist Universitätsklinik - Chiropraktische\n' +
            '                                        Medizin'
    }
export const dataKlimaball =
    {
            src: klimaball,
            alt: 'Klimaball - Digitale Ebene in AR',
            projectCategory: 'Web-Applikation mit Augmented Reality',
            projectName: 'Klimaball - Support und Weiterentwicklung der digitalen Ebene '
    }
export const dataKita =
    {
            src: kita,
            alt: 'Kita Löwehärz Logo',
            projectCategory: 'Logo',
            projectName: 'Kita Löwehärz - Wahlen b. Laufen'
    }


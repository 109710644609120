import React from 'react';
import './App.css';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import {Helmet} from "react-helmet";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import Home from './components/pages/HomePage/Home'
import PrivacyView from "./components/pages/PrivacyPage/PrivacyView";
import ImpressumView from "./components/pages/ImpressumPage/ImpressumView";
import AffiliateView from "./components/pages/AffiliatePage/AffiliateView";
import NotFoundView from "./components/pages/NotFoundPage/NotFoundView";

function App() {
    return (
        <Router>
            <Helmet>
                <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700;800;900&display=swap" rel="stylesheet"/>
            </Helmet>
            <ScrollToTop/>
            <NavBar/>
            <Routes>
                <Route path='/' element={<Home/>}/>
                <Route path='/privacy-policy' element={<PrivacyView/>}/>
                <Route path='/impressum' element={<ImpressumView/>}/>
                <Route path='/affiliate' element={<AffiliateView/>}/>

                <Route exact path='' element={<NotFoundView/>}/>
                <Route path="*" element={<NotFoundView/>} />


            </Routes>
            <Footer/>
        </Router>
    );
}

export default App;

import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import './Services.css'

function ServicesView() {
    return (
        <>
            <section id="services">
                <div className="container">
                    <div className="row align-content-between">
                        <div className="col-md-12">
                            <h2>Leistungen ✨</h2>
                            <h4>Durchdachte Webseiten für unkomplizierte, wertvolle Kommunikation.</h4>
                            <br/>
                            <Row xs={1} md={2} className="g-4">
                            <Col>
                                <Card id="serviceCards" className="text-center  h-100">
                                <br/>
                                <h3>✍🏻</h3>
                                <Card.Title className="h5">Konzept & Design</Card.Title>
                                <Card.Body>           
                                    <Card.Text className="w-90">
                                        Unsere Webseiten überzeugen nicht nur durch ansprechendes Design, sondern auch durch durchdachte Funktionalität. Sie sind alle responsive, optimiert für Endgeräte und passen sich dynamisch an verschiedene Bildschirmgrössen an.
                                    </Card.Text>
                                </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <Card id="serviceCards" className="text-center  h-100">
                                <br/>
                                <h3>🤓</h3>
                                <Card.Title className="h5">Unkompliziert & Individuell</Card.Title>
                                <Card.Body>           
                                    <Card.Text className="w-90">
                                        Unkomplizierte und individuelle Beratung, die auf deine Bedürfnisse eingeht.
                                    </Card.Text>
                                </Card.Body>
                                </Card>
                            </Col>

                            <Col>
                                <Card id="serviceCards" className="text-center  h-100">
                                <br/>
                                <h3>👩🏼‍💻👩🏻‍💻</h3>
                                <Card.Title className="h5">Webentwicklung & Testing</Card.Title>
                                <Card.Body>           
                                    <Card.Text className="w-90">
                                        Die Inhalte werden integriert und alle Funktionen sowie Darstellungen werden sorgfältig überprüft und getestet.
                                    </Card.Text>
                                </Card.Body>
                                </Card>
                            </Col>

                            <Col>
                                <Card id="serviceCards" className="text-center  h-100">
                                <br/>
                                <h3>🙌🏻</h3>
                                <Card.Title className="h5">Support & Wartung</Card.Title>
                                <Card.Body>           
                                    <Card.Text className="w-90">
                                    Das Webprojekt wird live geschaltet und in deine Obhut übergeben. Wir stehen Dir weiterhin für Support und Wartung zur Verfügung.
                                    </Card.Text>
                                </Card.Body>
                                </Card>
                            </Col>
                            </Row>

                            <div>
                                <br/>
                                <a href="#contact" class="btn btn-primary float-end">Offerte anfragen</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ServicesView;

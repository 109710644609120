import React from 'react';
import flow from '../../../../assets/img/information_flow (1).svg';
import './AboutView.css'

function AboutView(){
    return (
        <>
            <section id="about">
                <div className="container">
                    <div className="row align-content-between">
                        <div className="col-md-8">
                            <img src={flow} alt="Idee aufhängen" id="flow"
                                 className="center d-md-none w-50 pt-4"/>
                            <h2>Über uns</h2>
                            <p>
                                Unsere Köpfe sprudeln voller Ideen und Visionen für die Zukunft.

                                Um uns eine Plattform aufbauen zu können, auf der wir unsere Ideen realisieren werden,
                                haben wir im September 2019 gemeinsam "Vaynie" gegründet. <br/><br/>

                                Der Name «Vaynie» ist bereits Jahre vor der Gründung im Spass entstanden und ist unser
                                Freundschafts-Spitzname. Er ist eine Kombination unserer Vornamen. VAY, aus Vay Lien und
                                NIE aus
                                Leonie. Seither stand fest: wenn wir unser eigenes Unternehmen gründen, heisst es
                                «Vaynie».
                                <br/><br/>
                                Freude an dem was wir machen, ist einer der wichtigsten Werte unseres
                                Unternehmens. Egal was wir tun, wir machen es, weil wir Freude daran haben. Es ist Lebensqualität -
                                der Grund, warum wir Vaynie
                                gründeten. Dasselbe gilt auch für unsere Kunden – wir möchten, dass
                                sie Freude an unseren Produkten
                                und Lösungen haben. Wir sind sehr gespannt, was die Zukunft bringt und
                                freuen uns riesig drauf.
                            </p>
                        </div>
                        <div className="col-md-4">
                            <img src={flow} alt="Idee aufhängen"
                                 className="d-none d-md-block  " id="imgAboutLg"/>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutView;
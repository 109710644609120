import React from 'react';
import {FaInstagram, FaFacebook} from "react-icons/fa";
import {Link} from "react-router-dom";

function Footer() {
    return (
        <>
        <footer className="footer">
            <div className="container">
                <div className="row  ">
                    <div className="col-12 col-md-4">
                        <h4>Vaynie KLG</h4>
                        <p>
                            Im Kunzental 8<br/>
                            4310 Rheinfelden<br/>
                            <a href="tel:+41774902424">📱 +41 77 490 24 24</a><br/>
                            <a href="mailto:info@vaynie.com">💌 info@vaynie.com</a>
                        </p>
                    </div>
                    <div className="col-12 col-md-4" id='social'>
                        <h4>Social Media</h4>
                        <a href="https://www.instagram.com/vaynie.ch/ " rel="noreferrer noopener"  target="_blank">
                            <FaInstagram className="fa-2x btn-social "/></a>
                    <a href="https://www.facebook.com/vaynieKLG/" rel="noreferrer noopener"  target="_blank" id='fb-icon' style={{paddingLeft: '1em'}}>
                        <FaFacebook className="fa-2x btn-social"/></a>
            </div>
            <div className="col-12 col-md-4">
                <h4>Rechtliches</h4>
                <Link to={"./impressum"}>Impressum</Link><br/>
                <Link to={"./privacy-policy"}>Datenschutzerklärung</Link><br/>
            </div>
        </div>
        <div className={'copyright-div'} style={{marginTop: '3em'}}>
            <p id="copyright" className="text-center "> Made with ❤️| © 2022 Vaynie KLG</p>
        </div>
        </div>
</footer>
</>
)
}

export default Footer

import './PortfolioView.css'
import {dataNicer, dataChiro, dataKlimaball, dataKita,sic22, stc22 } from "../Data";
import PortfolioBox from "../../../PortfolioBox";
import React from 'react'

function PortfolioView() {
    return (
        <>
            <section id="portfolio">
                <div className="container p-4 text-right">
                    <h2>Portfolio</h2>
                    <p>Um Dir einen kleinen Eindruck zu geben, was wir bis jetzt an Projekten gemacht haben, ist hier
                        eine kleine Bildergallerie.</p>
                </div>

                <div className="container-fluid p-0">
                    <div className="row no-gutters">
                        <PortfolioBox {...dataKlimaball}/>
                        <PortfolioBox {...dataChiro}/>
                        <PortfolioBox {...dataNicer}/>
                        <PortfolioBox {...dataKita}/>
                    </div>
                </div>
                <div className={'banner container-fluid'}>
                    <img src={sic22.img} alt="Swiss Innovation Challenge 2022"/>
                    <div>
                        <img src={stc22.img} alt="Swiss Startup Challenge 2022"/>
                        <p>2022 | 3. Platz 🎉</p>
                    </div>
                </div>
            </section>
        </>
    );
}

export default PortfolioView

import React from 'react';
import alien from '../../../assets/img/alien.svg'
import './NotFoundView.css';

function NotFoundView (){
    return(
        <>
            <section id={'not-found'} className={'vh-100'}>
                <div className="container ">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <img src={alien} alt="Alien" className="w-75 "/>
                        </div>
                        <div className="col-12 col-md-6">
                            <p className="title-font-primary">404</p>
                            <h2>Diese Seite existiert nicht.</h2>
                            <p>Anscheinend hast Du Dich auf der Seite verlaufen. </p>
                            <a href="/" className="btn btn-primary">Zur Startseite</a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default NotFoundView
import React from 'react';
import StartView from "./StartViewPage/StartView";
import SkillsView from "./SkillsPage/SkillsView";
import TeamView from "./TeamPage/TeamView";
import AboutView from "./AboutPage/AboutView";
import PortfolioView from "./PortfolioPage/PortfolioView";
import ServicesView from './ServicesPage/Services';
import './Home.css'
import ContactView from "./ContactPage/ContactView";
import {Helmet} from "react-helmet";
import img from '../../../assets/img/vaynie_website.png'


function Home() {

    const title = 'Vaynie - Webseiten Entwicklung';
    const description = 'Wir entwickeln und vermarkten Web Lösungen';
    const keywords = ['Startup', 'IT', 'Softwareentwicklung', 'Full-Stack-Development', 'innovativ', "Webentwicklung", 'Blockchain', 'Web 3.0', 'Dapps'];
    const date = new Date();

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description}/>
                <meta name='keywords' content={keywords}/>
                <meta name='date' content={date}/>
                <meta property="og:title" content={title}/>
                <meta property="og:description" content={description}/>
                <meta property="og:image" content={img}/>

                <meta property="twitter:card" content="summary_large_image"/>
                <meta property="twitter:url" content="https://vaynie.ch/"/>
                <meta property="twitter:title" content={title}/>
                <meta property="twitter:description" content={description}/>
                <meta property="twitter:image" content={img}/>

            </Helmet>
            <StartView/>
            <ServicesView/>
            <SkillsView/>
            <AboutView/>
            <TeamView/>
            <PortfolioView/>
            <ContactView/>
        </>
    );
}

export default Home;

import React from 'react';
import './SkillsView.css'
import ListGroup from 'react-bootstrap/ListGroup'
import Tab from 'react-bootstrap/Tab'
import {Row, Col} from "react-bootstrap";

import js from '../../../../assets/img/skills/javaScript.png'
import react from '../../../../assets/img/skills/react.png'
import figma from '../../../../assets/img/skills/Figma.png'
import illu from '../../../../assets/img/skills/adobeIllustrator.png'
import psd from '../../../../assets/img/skills/adobePhotoshop.png'
import firebase from '../../../../assets/img/skills/firebase.png'
import python from '../../../../assets/img/skills/python.png'
import premiere from '../../../../assets/img/skills/premiere.png'
import afterEffects from '../../../../assets/img/skills/afterEffects.png'
import solidity from '../../../../assets/img/skills/solidity.png'
import ganache from '../../../../assets/img/skills/ganache.png'
import truffle from '../../../../assets/img/skills/truffle.png'
import metaMask from '../../../../assets/img/skills/metaMask.png'
import infura from '../../../../assets/img/skills/infura.png'
import ipfs from '../../../../assets/img/skills/ipfs.png'
import remix from '../../../../assets/img/skills/remix.png'

function SkillsView() {
    return (
        <>
            <section id="skills">
                <div className="container " defaultactivekey={'#list-frontend'}>

                    <h2>Skills</h2>
                    <Tab.Container id="list-group-tabs-example" defaultActiveKey="#design">
                        <Row>
                            <Col sm={4}>
                                <ListGroup>
                                    <ListGroup.Item action href="#design">
                                        Design
                                    </ListGroup.Item>
                                    <ListGroup.Item action href="#frontend">
                                        Frontend
                                    </ListGroup.Item>
                                    <ListGroup.Item action href="#backend">
                                        Backend
                                    </ListGroup.Item>
                                    <ListGroup.Item action href="#blockchain">
                                        Blockchain
                                    </ListGroup.Item>
                                </ListGroup>
                            </Col>
                            <Col sm={8}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="#blockchain">
                                        <p>Für den Blockchain Bereich setzen wir folgende Technologien ein:</p>
                                        <div className={'img-div'}>
                                            <img src={solidity} alt="Solidity" className="img-skills" title="Solidity"/>
                                            <img src={ganache} alt="Ganache" className="img-skills" title="Ganache"/>
                                            <img src={truffle} alt="Truffle" className="img-skills" title="Truffle"/>
                                            <img src={metaMask} alt="MetaMask" className="img-skills" title="MetaMask"/>
                                            <img src={infura} alt="Infura" className="img-skills" title="Infura"/>
                                            <img src={ipfs} alt="ipfs" className="img-skills" title="ipfs"/>
                                            <img src={remix} alt="remix" className="img-skills" title="remix"/>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="#frontend">
                                        <p>Zur Entwicklung von Webseiten verwenden wir React. Für die Umsetzung einer
                                            Gesamtlösung (Android, IOS und Webseite) vertrauen wir voll und ganz
                                            auf
                                            React Native. </p>
                                        <div className={'img-div'}>

                                            <img src={react} alt="React" className="img-skills" title="React"/>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="#design">
                                        <p>Bevor wir mit dem Coden beginnen, setzen wir uns mit dem UI und UX Design
                                            auseinander. Wireframes, Mockups und Klick-Prototypen erstellen wir im
                                            Figma.
                                            Für
                                            alle weiteren gestalterischen Elemente verwenden wir Adobe Produkte.</p>
                                        <div className="img-div">
                                            <img src={figma} alt="Figma" className="img-skills" title="Figma"/>
                                            <img src={psd} alt="Adobe Photoshop" className="img-skills" title="Adobe Photoshop"/>
                                            <img src={illu} alt="Adobe Illustrator" className="img-skills" title="Adobe Illustrator"/>
                                            <img src={premiere} alt="" className="img-skills" title="Adobe Premiere Pro"/>
                                            <img src={afterEffects} alt="Adobe After Effects" className="img-skills" title="Adobe After Effects"/>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="#backend">
                                        <p>Programmiert wird immer in der Sprache, welche gerade am Besten geeignet ist,
                                            wobei
                                            wir uns zwischen Python und JavaScript entscheiden. Komplexe Backend-Lösungen
                                            gestalten wir mit Hilfe von Google's Firebase.</p>
                                        <div className="img-div">
                                            <img src={python} alt="Python" className="img-skills" title="Python"/>
                                            <img src={js} alt="JavaScript" className="img-skills" title="JavaScript"/>
                                            <img src={firebase} alt="Firebase" className="img-skills" title="Firebase"/>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </div>
            </section>
        </>
    )
}

export default SkillsView;
import React from 'react';
import './StartView.css';
import img from '../../../../assets/img/fast_working (1).svg'

function StartView() {
    return (
        <>
            <section id="masthead" className="vh-100">
                <div className="container  pt-5 ">
                    <h3>Vaynie</h3>
                    <h1 className="w-auto">Coole innovative Webseiten</h1>
                    <p className="w-75">Wir lieben es, Software zu entwickeln, damit Du dein Unternehmen auf die nächste Stufe heben kannst.</p>
                    <a className="btn btn-primary js-scroll-trigger " href="#services">Erfahre mehr!</a> <br/>
                    <div id='mh-img'>
                        <img src={img} alt="" className="w-50 float-right "/>
                    </div>
                </div>
            </section>
        </>
    )
}

export default StartView;
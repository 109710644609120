import React from "react";

function PortfolioBox({href, src, alt, projectCategory, projectName}) {

    return (
        <>
            <div className="col-lg-3 col-sm-6">
                <div className="portfolio-box" >
                    <img className="img-fluid" src={src}
                         alt={alt}/>
                    <div className="portfolio-box-caption">
                        <div className="project-category font-weight-bold">{projectCategory}</div>
                        <div className="project-name">{projectName}</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PortfolioBox

import React, {useState} from 'react';
import './ContactView.css';
import {Form, Button, Row} from "react-bootstrap";
import emailjs from '@emailjs/browser';

const Result = () => {
    return (
        <p>Deine Nachricht wurde versendet. Vielen Dank!</p>
    )
}

function ContactView() {

    const [result, showResult] = useState(false);
    const [validated, setValidated] = useState(false);

    const sendEmail = (e) => {
        //e.preventDefault();
        const form = e.target;
        e.preventDefault();

        console.log(form.checkValidity)
        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true);
        }
        if(form.checkValidity() === true){
            emailjs.sendForm('service_g6dhpum', 'template_205hv9e', form, 'user_Egn2y1eKZeekWfOA561MW')
                .then((result) => {
                    console.log(result.text);
                }, (error) => {
                    console.log(error.text);
                });

            showResult(true);
            setValidated(false);
            e.target.reset();
        }
    };

    return (
        <>
            <section className="container" id="contact">
                <Row className='form-row'>
                    <div className="col-12 col-md-6 ">
                        <h2>Hi! 🤗</h2>
                        <h3>Kontaktiere uns.</h3>
                        <p className='untertitel'>Wir stellen Dir gerne eine <b>kostenlose und unverbindliche Offerte</b> zur Verfügung und freuen uns auf Deine Nachricht!</p>
                        <Form noValidate validated={validated} onSubmit={sendEmail} id='contactForm'>
                            <div className='name-row'>
                                <Form.Group className="mb-3 fullwidth " controlId="formSurname">
                                    <Form.Label>Vorname</Form.Label>
                                    <Form.Control type="text" placeholder="Vorname" name='formSurname' required/>
                                </Form.Group>
                                <div className='p-3'/>
                                <Form.Group className="mb-3 fullwidth" controlId="formName">
                                    <Form.Label>Nachname</Form.Label>
                                    <Form.Control type="text" placeholder="Nachname" name='formName' required/>
                                </Form.Group>
                            </div>

                            <Form.Group className="mb-3" controlId="formEmail">
                                <Form.Label>Email Adresse</Form.Label>
                                <Form.Control type="email" placeholder="Email Adresse" name='formEmail' required/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formMessage">
                                <Form.Label>Nachricht</Form.Label>
                                <Form.Control as="textarea" rows={3} placeholder='Nachricht schreiben...'
                                              name='formMessage' required/>
                            </Form.Group>

                                <Button variant="primary" type="submit">
                                    Senden
                                </Button>
                            <div className='result-div'>
                                {result ? <Result/> : null}
                            </div>
                        </Form>
                    </div>
                </Row>
            </section>
        </>
    )
}

export default ContactView